/*
    HTML for equalizer
    <div class="row equalizer">
        <div class="col-lg-4 col-md-6 col-sm-6 col-12"></div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12"></div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12"></div>
    </div>
*/

$(() => {
    const $equalizer = $('.equalizer');
    const equalizer = () => {
        $equalizer.each(function() {
            const $equalize = $(this).find('.equalize');
            let height = 0;

            $equalize.removeAttr('style');

            $equalize.each(function() {
                const $this = $(this);
                if ($this.height() > height) {
                    height = $this.height();
                }
            });
            $equalize.height(height);
        });
    };


    let w = $(window).width();
    $(window).on('resize', function() {
        if ($(window).width() === w) return;
        w = $(window).width();
        equalizer();
    });
    setTimeout(() => {
        equalizer();
    }, 500);
});
