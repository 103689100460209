$(() => {
    const $navmobile = $('nav');
    const $hamburger = $('.hamburger-button');
    const $mobilenavbouncefixer = $('.mobile-nav-bounce-fixer');
    const $body = $('body');
    let isOpen = false;

    // Open Close Mobile Menu
    $hamburger.on('click', () => {
        if ($hamburger.hasClass('open')) {
            isOpen = false;
            $hamburger.removeClass('open');
            $navmobile.removeClass('open');
            $body.removeClass('open');
            $mobilenavbouncefixer.removeClass('open');
        } else {
            isOpen = true;
            $hamburger.addClass('open');
            $navmobile.addClass('open');
            $body.addClass('open');
            setTimeout(() =>{
                if (isOpen) {
                    $mobilenavbouncefixer.addClass('open');
                }
            }, 500);
        }
    });
});
